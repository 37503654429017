import {
    EXPIRES_SIGNATURE,
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    INIT_URL, SET_APP_AUTHORIZATION, SET_EMAIL,
    SIGNOUT_USER_SUCCESS,
    USER_DATA,
    USER_TOKEN_SET
} from "../../constants/ActionTypes";
import axios from '../../util/Api'
import Helpers from "../../util/Helpers";

import {showMessage} from "./Common";

export const setInitUrl = (url) => {
    return {
        type: INIT_URL,
        payload: url
    };
};

export const setExpiresSignature = (params) => {
    return {
        type: EXPIRES_SIGNATURE,
        payload: params
    };
};


export const setToken = (params) => {
    return {
        type: EXPIRES_SIGNATURE,
        payload: params
    };
};

export const setAuthEmail = (params) => {
    return {
        type: SET_EMAIL,
        payload: params
    };
};


export const userSignUp = ({email, password, first_name, last_name}) => {

    const helper = new Helpers();
    let deviceName = helper.getDeviceName();

    return (dispatch) => {
        dispatch({type: FETCH_START});
        axios.post('/register', {
                email: email,
                password: password,
                // password_confirmation: password,
                first_name: first_name,
                last_name: last_name,
                device_name: deviceName,
            }
        ).then(({data}) => {

            if (data.success) {
                localStorage.setItem("token", JSON.stringify(data.token));

                axios.defaults.headers.common['authorization'] = "Bearer " + data.token;
                dispatch({type: FETCH_SUCCESS});
                dispatch({type: USER_TOKEN_SET, payload: data.token});
                dispatch({type: USER_DATA, payload: data.user});
                dispatch(setInitUrl('/email/verify'));
            } else {
                console.log("payload: data.error", data.error);
                dispatch({type: FETCH_ERROR, payload: data.error});
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            console.log("Error****:", error.message);
        });
    }
};

export const userSignIn = ({email, password}) => {

    const helper = new Helpers();
    let deviceName = helper.getDeviceName();

    return (dispatch) => {
        dispatch({type: FETCH_START});
        dispatch(setAuthEmail(email));
        axios.post('/sanctum/token', {
                email: email,
                password: password,
                device_name: deviceName,
            }, {
                withCredentials: true
            }
        ).then(({data}) => {

            if (data.success) {
                localStorage.setItem("token", JSON.stringify(data.token));
                //helper.setCookie('additional_auth_token', data.additional_auth_token, 1);

                axios.defaults.headers.common['Authorization'] = "Bearer " + data.token;
                dispatch({type: FETCH_SUCCESS});
                dispatch({type: USER_TOKEN_SET, payload: data.token});
                
                // Check if the localStorage item 'redirectFullPath' is not empty or null
                const redirectPath = localStorage.getItem('redirectFullPath');

                if (redirectPath) {
                    // If it's not empty or null, redirect to that path
                    const tok = data.token
                    window.location.href = redirectPath+ '?referer='+tok;
                    localStorage.removeItem('redirectFullPath')
                } else {
                    dispatch(setInitUrl('/home'));
                }
            } else {
                dispatch({type: FETCH_ERROR, payload: data.error});
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            console.log("Error****:", error.message);
        });
    }
};

export const getUser = (location = "/") => {
    // console.log();
    let token = JSON.parse(localStorage.getItem("token"));
    //  axios.defaults.headers.common['Accept'] = " application/json";
    axios.defaults.headers.common['Authorization'] = "Bearer " + token;

    return (dispatch) => {
        dispatch({type: FETCH_START});
        axios.get('user').then(({data}) => {

            if (data.success) {
                dispatch({type: FETCH_SUCCESS});
                dispatch({type: USER_DATA, payload: data.user});

            } else {
                dispatch({type: FETCH_ERROR, payload: ''});
                localStorage.removeItem("token");
                console.log(window.location.pathname);
                if (window.location.pathname === '/login' || window.location.pathname === '/register' || window.location.pathname === '/' || window.location.pathname === '/password-reset'|| window.location.pathname === '/employee-validations') {

                }else if(data.error === "Unauthenticated."){
                    // Get the full path (URL) of the current page
                    const fullPath = window.location.href;
                    // Save the full path in local storage
                    localStorage.setItem('redirectFullPath', fullPath);
                    window.location.href = '/login';
                }

            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: '!'});
            localStorage.removeItem("token");
            console.log("Error****:", error.message);
        });
    }
};


export const userSignOut = () => {
    let token = JSON.parse(localStorage.getItem("token"));

    axios.defaults.headers.common['Authorization'] = "Bearer " + token;
    return (dispatch) => {
        dispatch({type: FETCH_START});

        axios.post('/logout').then(({data}) => {

            if (data.success) {
                localStorage.removeItem("token");

                dispatch({type: FETCH_SUCCESS});
                dispatch({type: SIGNOUT_USER_SUCCESS});

            } else {
                localStorage.removeItem("token");
                dispatch({type: FETCH_ERROR, payload: data.error});
                dispatch({type: SIGNOUT_USER_SUCCESS});
            }
            window.location.href = '/login';
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            localStorage.removeItem("token");
            dispatch({type: SIGNOUT_USER_SUCCESS});
            console.log("Error****:", error.message);
        });
    }
};

export const sendVerifyNotification = () => {
    let token = JSON.parse(localStorage.getItem("token"));

    axios.defaults.headers.common['Authorization'] = "Bearer " + token;
    return (dispatch) => {
        dispatch({type: FETCH_START});

        axios.post('/email/verification-notification').then(({data}) => {

            // if (data.success) {

            dispatch({type: FETCH_SUCCESS});
            dispatch(showMessage("New Verification Link has been successfully sent to your email address"));
            // } else {
            //     dispatch({type: FETCH_ERROR, payload: data.error});
            // }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            console.log("Error****:", error.message);
        });
    }
};


export const verifyAccount = (id, hash, expires, signature) => {
    let token = JSON.parse(localStorage.getItem("token"));

    axios.defaults.headers.common['Authorization'] = "Bearer " + token;
    return (dispatch) => {
        dispatch({type: FETCH_START});

        axios.get('/email/verify/' + id + '/' + hash + "?expires=" + expires + "&signature=" + signature).then(({data}) => {

            if (data.success) {

                dispatch({type: FETCH_SUCCESS});
                dispatch(showMessage("Your Account has been successfully verified."));

            } else {
                dispatch({
                    type: FETCH_ERROR,
                    payload: "Problem verifying your email, please resend the verification link"
                });
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            console.log("Error****:", error.message);
        });
    }
};

export const setApplicationAuthorization = (authorization) => {

    return {
        type: SET_APP_AUTHORIZATION,
        payload: authorization
    };
};