import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "../util/asyncComponent";

const App = ({match}) => (
    <div className="gx-main-content-wrapper">
        <Switch>
            <Route path={`${match.url}email/verify`} component={asyncComponent(() => import('./VerifyEmail'))}/>
            <Route path={`${match.url}email/verifying/:id/:hash`}
                   component={asyncComponent(() => import('./Verifying'))}/>
            <Route path={`${match.url}verified`} component={asyncComponent(() => import('./VerificationSuccess'))}/>
            <Route path={`${match.url}change-password`} component={asyncComponent(() => import('./ChangePassword'))}/>
            <Route path={`${match.url}account-settings`} component={asyncComponent(() => import('./AccountSettings'))}/>
            <Route path={`${match.url}home`} component={asyncComponent(() => import('./Home'))}/>
            <Route path={`${match.url}employee-validations`} component={asyncComponent(() => import('./MonthlyValidation'))}/>
            <Route path={`${match.url}learning-center`} component={asyncComponent(() => import('./LearningCenter'))}/>
        </Switch>
    </div>
);

export default App;
